package au.com.aonea.sections

import androidx.compose.runtime.Composable
import au.com.aonea.components.sectionTitle
import au.com.aonea.models.Section
import au.com.aonea.util.Constant
import au.com.aonea.util.Res
import com.stevdza.san.kotlinbs.components.BSAccordion
import com.stevdza.san.kotlinbs.models.AccordionItem
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun linkSection(breakpoint: Breakpoint) {
    Box(
        modifier = Modifier
            .id(Section.LINKS.id)
            .fillMaxWidth()
            .padding(topBottom = Constant.SECTION_PADDING.px),
        contentAlignment = Alignment.Center
    ) {
        linkContent(breakpoint = breakpoint)
    }
}

@Composable
fun linkContent(breakpoint: Breakpoint) {

    Column(
        modifier = Modifier
            .fillMaxWidth(),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {


        sectionTitle(
            modifier = Modifier
                .margin(bottom = 25.px),
            alignment = Alignment.CenterHorizontally,
            title = Section.LINKS.title
        )


        Image(
            modifier = Modifier
                .maxWidth(if (breakpoint >= Breakpoint.MD) 160.px else 80.px),
            src = Res.Images.ato_app_logo,
            desc = "ATO App Logo"
        )

        Row(
            horizontalArrangement = Arrangement.Center
        ) {

            P(
                attrs = Modifier
                    .fillMaxWidth()
                    .margin(topBottom = 10.px)
                    .fontSize(if (breakpoint < Breakpoint.SM) 14.px else 16.px)
                    .fontWeight(FontWeight.ExtraBlack)
                    .toAttrs()
            ) {
                Text(Constant.ATO_APP)
            }
        }

        externalAppStoreLinks(breakpoint = breakpoint)
        stevdzaSanKandy(breakpoint = breakpoint)
    }
}

@Composable
fun externalAppStoreLinks(breakpoint: Breakpoint) {


    Row(
        modifier = Modifier.fillMaxWidth(
            if (breakpoint <= Breakpoint.SM) 95.percent else if (breakpoint > Breakpoint.LG) 70.percent else 82.percent
        ),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Box(
            modifier = Modifier
                .height(if (breakpoint <= Breakpoint.SM) 200.px else if (breakpoint > Breakpoint.LG) 220.px else 210.px)
                .width(if (breakpoint <= Breakpoint.SM) 200.px else if (breakpoint > Breakpoint.LG) 350.px else 300.px),
            contentAlignment = Alignment.Center
        ) {
            Link(
                path = Constant.ATO_APP_STORE_LINK,
                openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
            ) {
                Box(
                    modifier = Modifier,
                    contentAlignment = Alignment.Center
                ) {
                    Image(
                        modifier = Modifier
                            .fillMaxWidth(75.percent),
                        src = Res.Images.app_store_logo,
                        desc = "link img"

                    )
                }
            }
        }

        Box(
            modifier = Modifier
                .height(if (breakpoint <= Breakpoint.SM) 250.px else if (breakpoint > Breakpoint.LG) 220.px else 210.px)
                .width(if (breakpoint <= Breakpoint.SM) 200.px else if (breakpoint > Breakpoint.LG) 350.px else 300.px),
            contentAlignment = Alignment.Center
        ) {
            Link(
                path = Constant.ATO_APP_GOOGLE_PLAY_LINK,
                openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
            )
            {
                Box(
                    modifier = Modifier,
                    contentAlignment = Alignment.Center
                ) {

                    Image(
                        modifier = Modifier
                            .fillMaxWidth(90.percent),
                        src = Res.Images.google_play_logo,
                        desc = "google"
                    )

                }
            }
        }
    }
}


@Composable
fun stevdzaSanKandy(
    breakpoint: Breakpoint
) {

    Row(
        modifier = Modifier
            .fillMaxWidth(95.percent)
                ,
        horizontalArrangement = Arrangement.Center
    ) {

        BSAccordion(
            modifier = Modifier.width(if (breakpoint <= Breakpoint.SM) 350.px else if (breakpoint > Breakpoint.LG) 450.px else 400.px),
            items = listOf(
                AccordionItem(
                    title = Constant.ATO_SIMPLE_TAX_CALCULATOR,
                    content = {
                        Link(
                            path = Constant.ATO_SIMPLE_TAX_CALC_LINK,
                            openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
                        ) {
                            Row(
                                horizontalArrangement = Arrangement.Center
                            )
                            {
                                Image(
                                    modifier = Modifier
                                        .width(80.percent)
                                    ,
                                    src = Res.Images.skills1,
                                    desc = "calculator"
                                )
                            }
                        }
                    },
                    defaultOpened = true
                ),
                AccordionItem(
                    title = Constant.ATO_OCCUPATION_SPECIFIC_DEDUCTIONS,
                    content = {
                        Link(
                            path = Constant.ATO_OCCUPATION_DEDUCTIONS_LINK,
                            openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
                        ) {
                            Row(
                                horizontalArrangement = Arrangement.Center
                            ) {

                                Image(
                                    modifier = Modifier
                                        .width(80.percent)
                                    ,
                                    src = Res.Images.pilots,
                                    desc = "pilot"
                                )

                            }
                        }
                    }
                )
            )
        )
    }
}
