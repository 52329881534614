package au.com.aonea.models

enum class Testimonials(
    val nameOf: String,
    val local: String,
    val review: String,
    val rating: Int
) {
    TestOne(
        nameOf = "Rowan",
        local = "Software Developer",
        review = "Anthony is a highly professional and knowledgeable accountant who has saved me thousands of dollars over the years due to his diligence and attention to detail. I have no hesitation in recommending Advantage One Accounting to individuals and business alike",
        rating = 5
    ),

}
//    TestTwo(
//        nameOf = "",
//        local = "",
//        review = "",
//        rating = 0
//    ),
//    TestTree(
//        nameOf = "",
//        local = "",
//        review = "",
//        rating = 0
//    ),
//    TestFours(
//        nameOf = "",
//        local = "",
//        review = "",
//        rating = 0
//    ),
