package au.com.aonea.models

import au.com.aonea.util.Res

enum class Section(
    val id: String,
    val title: String,
    val path: String,
    val icon: String,
) {

    ABOUT(
        id = "about",
        title = "About",
        path = "#about",
        icon = Res.Icons.about,
    ),
    CONTACT_FORM(
        id = "contact",
        title = "Contact",
        path = "#contact",
        icon = Res.Icons.contact,
    ),
    TESTIMONIALS(
        id = "testimonials",
        title = "Testimonials",
        path = "#testimonials",
        icon = Res.Icons.testimonials
    ),

    LINKS(
        id = "links",
        title = "Links",
        path = "#links",
        icon = Res.Icons.link,
    ),
    INTRODUCTION_PARA(
        id = "intro",
        title ="Intro",
        path = "#intro",
        icon = ""
    ),

    HEADER(
        id = "header",
        title = "Header",
        path = "#header",
        icon = "",
    ),
    XERO(
        id = "xero",
        title = "Xero",
        path = "#xero",
        icon = "",
    ),

    FOOTER(
        id = "footer",
        title = "Footer",
        path = "#footer",
        icon = "",
    ),

    SKILLS(
    id = "skills",
    title = "Skills",
    path = "#skills",
    icon = Res.Icons.skills,
    ),
}