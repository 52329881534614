package au.com.aonea.pages

import androidx.compose.runtime.*
import au.com.aonea.components.*
import au.com.aonea.sections.*
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint

@Page
@Composable
fun homePage() {

    val breakpoint = rememberBreakpoint()
    var menuOpened by remember { mutableStateOf(false) }


//    Surface(SmoothColorStyle.toModifier().minHeight(100.vh).minWidth(100.vw)) {
    Box(
        modifier = Modifier.fillMaxSize()
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize(),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            headerContent(onMenuClicked = { menuOpened = true }, breakpoint = breakpoint)
            titleLoader(breakpoint = breakpoint)
            blockOColorLime()
            blockOColorGrey()
            introLoader(breakpoint = breakpoint)
            blockOColorLime()
            blockOColorGrey()
            serviceSection(breakpoint = breakpoint)
            blockOColorLime()
            blockOColorGrey()
            aboutSection(breakpoint = breakpoint)
            blockOColorLime()
            blockOColorGrey()
            xeroLogo(breakpoint = breakpoint)
            blockOColorLime()
            blockOColorGrey()
            contactSection(breakpoint = breakpoint)
            blockOColorLime()
            blockOColorGrey()
            testimonialsSection(breakpoint = breakpoint)
            blockOColorLime()
            blockOColorGrey()
            linkSection(breakpoint = breakpoint)
            blockOColorLime()
            blockOColorGrey()
            footerSection()

//            demoWidget()
//            modBox()
        }
        backToTopButton()
        if (menuOpened) {
            overflowMenu(onMenuClosed = { menuOpened = false })
        }
    }
}
