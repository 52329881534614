package au.com.aonea.styles

import au.com.aonea.models.Theme
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.focus
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px

val InputStyle by ComponentStyle {
    base {
        Modifier
            .border(
                width = 2.px,
                style = LineStyle.Solid,
                color = Theme.DimGrey.rgb
            )
            .transition(CSSTransition(property = "border", duration = 200.ms))
    }
    focus {
        Modifier.border(
            width = 2.px,
            style = LineStyle.Solid,
            color = Theme.AONEABlue.rgb
        )
    }
    hover {
        Modifier.border(
            width = 2.px,
            style = LineStyle.Solid,
            color = Theme.AONEABlue.rgb
        )
    }
}

val MainButtonStyle by ComponentStyle {
    base {
        Modifier
            .width(35.px)
//            .backgroundColor(Theme.White.rgb)
//            .color(Theme.White.rgb)
            .margin(leftRight = 0.px)
//            .padding(leftRight = 15.px)
            .transition(CSSTransition(property = "width", duration = 200.ms))
    }
    hover {
        Modifier.width(38.px)
    }
}

val SubmitButtonStyle by ComponentStyle {
    base {
        Modifier
            .width(95.px)
//            .backgroundColor(Theme.White.rgb)
//            .color(Theme.White.rgb)
            .margin(leftRight = 0.px)
//            .padding(leftRight = 15.px)
            .transition(CSSTransition(property = "width", duration = 200.ms))
    }
    hover {
        Modifier.width(105.px)
    }
}

val LinkButtonStyle by ComponentStyle {
    base {
        Modifier
            .width(210.px)
//            .backgroundColor(Theme.White.rgb)
//            .color(Theme.White.rgb)
            .margin(leftRight = 0.px)
//            .padding(leftRight = 15.px)
            .transition(CSSTransition(property = "width", duration = 200.ms))
    }
    hover {
        Modifier.width(285.px)
    }
}
