package au.com.aonea.models

import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgb

enum class Theme(
    val rgb: CSSColorValue
) {
    AONEABlue(rgb = rgb(120, 193, 243)),
    MainDark(rgb = rgb(69, 60, 103)),
    MainSecondary(rgb = rgb(109, 103, 228)),
    FirstContrast(rgb = rgb(70, 194, 203)),
    Highlight(rgb = rgb(242, 247, 161)),

    Black(rgb = rgb(0,0,0)),
    White(rgb = rgb(255,255,255)),
    MintCream(rgb = rgb(245,255,250)),
    DimGrey(rgb = rgb(244,249,249)),
}