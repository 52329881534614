package au.com.aonea.sections

import androidx.compose.runtime.Composable
import au.com.aonea.models.Section
import au.com.aonea.models.Theme
import au.com.aonea.util.Constant
import au.com.aonea.util.Res
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Canvas

@Composable
fun xeroLogo(breakpoint: Breakpoint) {
    Box(
        modifier = Modifier
            .id(Section.XERO.id)
            .padding(Constant.SECTION_PADDING.px)
        ,
        contentAlignment = Alignment.Center
    ) {
        logoContent(breakpoint = breakpoint)
    }

}

@Composable
fun logoContent(
    breakpoint: Breakpoint
) {

   Canvas(
       attrs = Modifier
           .width(if (breakpoint >= Breakpoint.MD) 450.px else if(breakpoint < Breakpoint.SM) 260.px else 340.px)
           .height(if (breakpoint >= Breakpoint.MD) 230.px else if(breakpoint < Breakpoint.SM) 210.px else 255.px)
           .border(width = 5.px, style = LineStyle.Ridge, color = Theme.AONEABlue.rgb)
           .margin(all = 55.px)
           .toAttrs()

   )

    Box(
        modifier = Modifier
            .width(if (breakpoint >= Breakpoint.MD) 350.px else if(breakpoint < Breakpoint.SM) 180.px else 240.px)
            .height(if (breakpoint >= Breakpoint.MD) 160.px else if(breakpoint < Breakpoint.SM) 160.px else 190.px)
            .border(width = (3.5).px, style = LineStyle.Double, color = Theme.Black.rgb)
    )

    Image(
        src = Res.Images.xero_logo,
        modifier = Modifier
            .height(if (breakpoint >= Breakpoint.MD) 165.px else if(breakpoint < Breakpoint.SM) 120.px else 145.px),
        desc = "",
    )
}