package au.com.aonea.sections

import androidx.compose.runtime.Composable
import au.com.aonea.models.Theme
import au.com.aonea.util.Constant
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun footerSection() {

    val breakpoint = rememberBreakpoint()

    Column  (modifier = Modifier
        .fillMaxWidth()
        .margin(top = 75.px, bottom = 40.px
        ),
        horizontalAlignment = Alignment.CenterHorizontally
    ){
        P(
            attrs = Modifier
                .margin(bottom = 3.px)
                .fontFamily(Constant.FONT_FAMILY)
                .fontSize(if(breakpoint >= Breakpoint.MD) 16.px else 14.px)
                .fontWeight(FontWeight.Bold)
                .color(Theme.Black.rgb)
                .toAttrs()
        ) {
            Text(Constant.COPYRIGHT)
        }
        P(
            attrs = Modifier
                .margin(bottom = 20.px)
                .fontFamily(Constant.FONT_FAMILY)
                .fontSize(if(breakpoint >= Breakpoint.MD) 15.px else 13.px)
                .fontWeight(FontWeight.Thin)
                .color(Theme.Black.rgb)
                .toAttrs()
        ) {
            Text(Constant.ABN)
        }
        P(
            attrs = Modifier
                .margin(bottom = 4.px)
                .fontFamily(Constant.FONT_FAMILY)
                .fontSize(if(breakpoint >= Breakpoint.MD) 14.px else 12.px)
                .fontWeight(FontWeight.Normal)
                .color(Theme.Black.rgb)
                .toAttrs()
        ) {
            Text(Constant.SHOUT_OUT_1)
        }
        P(
            attrs = Modifier
                .margin(bottom = 4.px)
                .fontFamily(Constant.FONT_FAMILY)
                .fontSize(if(breakpoint >= Breakpoint.MD) 14.px else 12.px)
                .fontWeight(FontWeight.Normal)
                .color(Theme.Black.rgb)
                .toAttrs()
        ) {
            Text(Constant.SHOUT_OUT_2)
        }
        Link(
            path = Constant.KOBWEB_URL,
            openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
        ) {
            P(
                attrs = Modifier
                    .margin(bottom = 0.px)
                    .fontFamily(Constant.FONT_FAMILY)
                    .fontSize(if (breakpoint >= Breakpoint.MD) 14.px else 12.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.AONEABlue.rgb)
                    .toAttrs()
            ) {
                Text(Constant.KOBWEB_URL)
            }
        }

    }
}