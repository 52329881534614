package au.com.aonea.sections

import androidx.compose.runtime.Composable
import au.com.aonea.components.serviceCard
import au.com.aonea.models.Section
import au.com.aonea.models.Services
import au.com.aonea.models.Theme
import au.com.aonea.util.Constant
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun serviceSection(breakpoint: Breakpoint){
    Box (
        modifier = Modifier
            .id(Section.SKILLS.id)
            .padding(topBottom = Constant.SECTION_PADDING.px)
            .backgroundColor(Theme.White.rgb),

        contentAlignment = Alignment.Center
            ){
        serviceContent(breakpoint = breakpoint)
    }
}

@Composable
fun serviceContent(breakpoint: Breakpoint) {

    SimpleGrid(
        modifier = Modifier
            .maxWidth(if (breakpoint >= Breakpoint.MD) 100.percent else 95.percent)
        ,
        numColumns = numColumns(base = 1, sm = 2, md = 2)) {
            Services.values().forEach { skill ->
            serviceCard(skill = skill, breakpoint = breakpoint)

            }
        }

}