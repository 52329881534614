package au.com.aonea.util

object Constant {
    const val WEBSITE = "https://www.aonea.com.au"
//    const val TPB_LINK = "https://www.tpb.gov.au/public-register?practitioner=25716569"
    const val TPB_LINK = "https://www.tpb.gov.au/"
    const val FORM_SPREE_LINK = "https://formspree.io/f/mqkvpebz"
    const val ATO_APP_GOOGLE_PLAY_LINK = "https://play.google.com/store/search?q=ato&c=apps"
    const val ATO_APP_STORE_LINK = "https://apps.apple.com/au/app/australian-taxation-office/id664461825"
    const val ATO_SIMPLE_TAX_CALC_LINK = "https://www.ato.gov.au/calculators-and-tools/simple-tax-calculator/#Whichtaxrates"
    const val ATO_OCCUPATION_DEDUCTIONS_LINK = "https://www.ato.gov.au/individuals/income-deductions-offsets-and-records/occupation-and-industry-specific-guides/"
    const val SECTION_WIDTH = 1920
    const val SECTION_PADDING = 75
    const val FONT_FAMILY = "Roboto"

    const val INTRO_GAIN = "Gain the advantage"
    const val INTRO_ENGAGE = "Engage an experienced, qualified tax professional"
    const val INTRO_ENGAGE_SML_1 = "Engage an experienced,"
    const val INTRO_ENGAGE_SML_2 = "qualified tax professional"
    const val INTRO_CONTACT = "Contact Us"

    const val ATO_APP = "Keep track of your salary and super"
    const val ATO_SIMPLE_TAX_CALCULATOR = "Simple Tax Calculator"
    const val ATO_OCCUPATION_SPECIFIC_DEDUCTIONS = "Occupation Specific Deductions"

    const val UNDER_CONSTRUCTION = "Under Construction"


    const val CONTACT = "Contact"
    const val CONTACT_NAME_TITLE = "Name"
    const val CONTACT_NAME_HINT = "Full name"
    const val CONTACT_EMAIL_TITLE = "Email"
    const val CONTACT_EMAIL_HINT = "Email address"
    const val CONTACT_MESSAGE_TITLE = "Message"
    const val CONTACT_MESSAGE_HINT = "Your message"
    const val CONTACT_SUBMIT_BUTTON = "Submit"

    const val ABOUT_DIALOG = "Advantage One Accounting was created by team principle Anthony Craddock. With over 15 years experience, " +
            "we have a proven track record of delivering clients the best " +
            "possible outcomes. Our aim at Advantage One Accounting is to forge strong client relationships and to always provide timely, high quality tax" +
            " and accounting services."

    const val COPYRIGHT = "\u00a9 2023 Advantage One Accounting Pty Ltd"
    const val ABN = "ABN 11 621 294 504"
    const val SHOUT_OUT_1 = "www.aonea.com.au"
    const val SHOUT_OUT_2 = "powered by Kobweb"
    const val KOBWEB_URL = "https://github.com/varabyte/kobweb"

}

enum class JanglyLetters(
    val letter: String
)
{
    A1(letter = "m"),
    O2(letter = "e"),
    N3(letter = "n"),
    E4(letter = "u"),
    A5(letter = ""),
}

object Res {

    object Icons {
        const val tick = "check-mark.png"
        const val cross = "cross_mark.png"
        const val link = "link.png"
        const val contact = "mail.png"
        const val about = "info.png"
        const val testimonials = "review.png"
        const val skills = "candidate.png"
        const val star = "GoldStar.png"
    }

    object Images {
        const val aonea_logo = "A1_logo_RGB_blues.png" // transparent background
        const val tbp_logo_landscape = "TPB_landscape.png"
        const val tbp_logo = "TPB_portrait.png"
        const val xero_logo = "xero.png"
        const val aonea_local = "QLD_Map.png"
        const val ato_app_logo = "ato.png"
        const val google_play_logo = "google-play-badge.png"
        const val app_store_logo = "apple-store-png-3.png"
        const val pilots = "Pilots.jpg"
        const val tax_return_1 = "CO_ITR.jpg"
        const val tax_return_2 = "tax.png"


        const val skills1 = "calc1_med.jpg"
        const val skills2 = "BAS.png"
        const val skills3 = "OIP.jpg"
        const val skills4 = "structure.png"
        const val skills5 = "Help_calculator.jpg"
        const val skills6 = "journal_800.jpg"
        const val skills7 = "financial_advice.jpg"

        //        const val aonea_logo = "A1_logo.png"
    }

}