package au.com.aonea.models

import au.com.aonea.util.Res
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.percent

enum class Services(
    val title: String,
    val percentage: CSSSizeValue<CSSUnit.percent>,
    val img: String,
    val offered: Boolean
) {


    TAXATION(
        title = "Tax Returns",
        percentage = 100.percent,
        img = Res.Images.tax_return_2,
        offered = true
    ),
    BUSINESS_ACTIVITY_STATEMENTS(
        title = "BAS",
        percentage = 100.percent,
        img = Res.Images.skills2,
        offered = true
    ),
    SMALL_BUSINESS_SPECIALISTS(
        title = "Small Business",
        percentage = 100.percent,
        img = Res.Images.skills3,
        offered = true
    ),
    BUSINESS_STRUCTURES(
        title = "Business Structures",
        percentage = 100.percent,
        img = Res.Images.skills4,
        offered = true
    ),
    TAX_PLANNING(
        title = "Tax Advice",
        percentage = 100.percent,
        img = Res.Images.skills5,
        offered = true
    ),
    BOOKKEEPING(
        title = "Bookkeeping",
        percentage = 100.percent,
        img = Res.Images.skills6,
        offered = true
    ),
    FINANCIAL_ADVICE(
        title = "Financial Advice",
        percentage = 0.percent,
        img = Res.Images.skills7,
        offered = false
    ),
}