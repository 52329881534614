package au.com.aonea.styles

import au.com.aonea.models.Theme
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.anyLink
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.css.ms

val NavigationItemStyle by ComponentStyle {
    base {
       Modifier
           .color(Theme.MainSecondary.rgb)
           .transition(CSSTransition(property = "color", duration = 900.ms))
    }
    anyLink {
        Modifier.color(Theme.MainSecondary.rgb)
    }
    hover {
        Modifier.color(Theme.Black.rgb)
    }
}