package au.com.aonea.sections

import androidx.compose.runtime.Composable
import au.com.aonea.models.Section
import au.com.aonea.models.Theme
import au.com.aonea.styles.MainButtonStyle
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaBars
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.ComponentVariant
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun headerContent(
    onMenuClicked: () -> Unit,
    breakpoint: Breakpoint
){
    Row(
        modifier = Modifier
            .id(Section.HEADER.id)
            .fillMaxWidth(100.percent)
            .height(if (breakpoint <= Breakpoint.SM) 55.px else 80.px)
            .backgroundColor(Theme.DimGrey.rgb)
        ,
        horizontalArrangement = Arrangement.End,
        verticalAlignment = Alignment.CenterVertically
    ) {
        header(onMenuClicked = onMenuClicked, breakpoint = breakpoint)

    }
}

@Composable
fun header(
    onMenuClicked: () -> Unit,
    breakpoint: Breakpoint
) {

    Row (
        modifier = Modifier
            .fillMaxWidth(if (breakpoint <= Breakpoint.SM) 95.percent else 90.percent)
            .backgroundColor(Theme.DimGrey.rgb)
            .margin(topBottom = 36.px),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ){
        leftSide(
            breakpoint = breakpoint,
            onMenuClicked = onMenuClicked
        )
        if (breakpoint > Breakpoint.SM) {
            rightSide()
        }
    }
}

@Composable
fun leftSide(
    breakpoint: Breakpoint,
    onMenuClicked: () -> Unit
) {

    Row(verticalAlignment = Alignment.CenterVertically) {
        if (breakpoint <= Breakpoint.SM) {
            FaBars(
                modifier = Modifier
                    .margin(leftRight = 15.px)
                    .margin(topBottom = 0.px)
//                    .padding(topBottom = 12.px)
                    .onClick {
                        onMenuClicked()
                    },
                size = if (breakpoint > Breakpoint.SM) IconSize.XXL else IconSize.LG
            )
        }
    }

}

@Composable
fun rightSide() {


    Row(
        modifier = Modifier
            .fillMaxWidth()
//            .borderRadius(r = 50.px)
//            .backgroundColor(Theme.LighterGray.rgb)
//            .padding(all = 20.px)
        ,
        horizontalArrangement = Arrangement.End,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Section.values().take(4).forEach { section ->

            Box (
                modifier = Modifier
                    .width(80.px)
                    .height(80.px),
                contentAlignment = Alignment.Center
                    ){
                Link(
                    path = section.path
                ) {
                    Image(
                        modifier = MainButtonStyle
                            .toModifier()
                            .color(Theme.AONEABlue.rgb),
                        src = section.icon,
                        desc = section.title,
                        variant = ComponentVariant.Empty
                    )
                }

            }
        }
    }
}