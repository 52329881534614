package au.com.aonea.sections

import androidx.compose.runtime.*
import au.com.aonea.components.contactForm
import au.com.aonea.components.sectionTitle
import au.com.aonea.models.Section
import au.com.aonea.util.Constant
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun contactSection(breakpoint: Breakpoint) {
    Box(
        modifier = Modifier
            .id(Section.CONTACT_FORM.id)
            .maxWidth(500.px)
            .padding(topBottom = Constant.SECTION_PADDING.px),
        contentAlignment = Alignment.Center
    ) {
        contactContent(breakpoint = breakpoint)
    }
}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun contactContent(breakpoint: Breakpoint) {
    var animatedRotation by remember { mutableStateOf(0.deg) }
    Column(
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint >= Breakpoint.MD) 100.percent
                else 90.percent
            ),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        sectionTitle(
            modifier = Modifier
                .fillMaxWidth()
                .margin(bottom = 25.px)
                .transform { rotate(animatedRotation) }
                .transition(CSSTransition(property = "transform", duration = 500.ms)),
            alignment = Alignment.CenterHorizontally,
            title = Constant.CONTACT
        )
        contactForm(breakpoint = breakpoint)

    }
}