package au.com.aonea.sections

import androidx.compose.runtime.Composable
import au.com.aonea.components.sectionTitle
import au.com.aonea.models.Section
import au.com.aonea.util.Constant
import au.com.aonea.util.Res
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun aboutSection(breakpoint: Breakpoint) {
    Box(
        modifier = Modifier
            .id(Section.ABOUT.id)
//            .maxWidth(500.px)
            .padding(topBottom = Constant.SECTION_PADDING.px),
        contentAlignment = Alignment.Center
    ) {
        aboutContent(breakpoint = breakpoint)
    }
}

@Composable
fun aboutContent(breakpoint: Breakpoint) {

    Column(
        modifier = Modifier
            .fillMaxWidth(if (breakpoint <= Breakpoint.SM) 85.percent else 65.percent),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {

        sectionTitle(
            modifier = Modifier
                .fillMaxWidth()
                .margin(bottom = 20.px),
            alignment = Alignment.CenterHorizontally,
            title = Section.ABOUT.title
        )
        Image(
            modifier = Modifier
                .height(if (breakpoint <= Breakpoint.SM) 200.px else 240.px)
                .margin(bottom = 60.px)
        ,
            src = Res.Images.aonea_local,
            desc = ""
        )

        P(
            attrs = Modifier
                .fontSize(if (breakpoint <= Breakpoint.SM) 18.px else 24.px)
                .toAttrs()
        ) {
            Text(Constant.ABOUT_DIALOG)
        }

    }
}