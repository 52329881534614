package au.com.aonea.components

import androidx.compose.runtime.Composable
import au.com.aonea.models.Theme
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun sectionTitle(
    modifier: Modifier = Modifier,
    title: String,
    alignment: Alignment.Horizontal = Alignment.Start
) {

    Column(
    ) {
        P(
            attrs = Modifier
                .margin(
                    top = 60.px,
                    bottom = 60.px
                )
                .fontSize(38.px)
                .fontWeight(FontWeight.Thin)
                .transition(CSSTransition(property = "margin", duration = 300.ms))
                .toAttrs()
        ) {
            Text(title)
        }
    }
}

@Composable
fun blockOColorGrey() {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .minHeight(1.px)
            .backgroundColor(Theme.DimGrey.rgb),
        content = {}
    )
}

@Composable
fun blockOColorLime() {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .minHeight(3.px)
            .backgroundColor(Theme.MintCream.rgb),
        content = {}
    )
}

