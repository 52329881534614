package au.com.aonea.components

import androidx.compose.runtime.Composable
import au.com.aonea.models.Testimonials
import au.com.aonea.models.Theme
import au.com.aonea.util.Constant.FONT_FAMILY
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun testimonialCard(
    modifier: Modifier = Modifier,
    testimonials: Testimonials,
    breakpoint: Breakpoint
) {


    Column (
        modifier = modifier
//            .maxWidth(500.px)
            .margin(leftRight = 12.px, topBottom = 6.px)
            .padding(all = 12.px)
            .border(width = 2.px, color = Theme.AONEABlue.rgb, style = LineStyle.Solid)
            .backgroundColor(Theme.MintCream.rgb)
            .borderRadius(r = 25.px)

            ){

        Row(
            modifier = Modifier
                .fillMaxWidth()
                .margin(bottom = 8.px),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            P(
                attrs = Modifier
                    .fillMaxWidth()
                    .margin(top = 0.px)
                    .fontSize(18.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.Black.rgb)
                    .toAttrs()
            ) {
                Text(testimonials.nameOf)
            }
            ratingBar(
                modifier = Modifier.margin(top = 0.px),
                stars = testimonials.rating
            )
        }
        P(
            attrs = Modifier
                .fillMaxWidth()
                .margin(topBottom = 0.px)
                .fontFamily(FONT_FAMILY)
                .fontSize(12.px)
                .fontWeight(FontWeight.Bolder)
                .color(Theme.Black.rgb)
                .toAttrs()
        ) {
            Text(testimonials.local)
        }

        P(
            attrs = Modifier
                .fillMaxWidth()
                .margin(top = 6.px)
                .fontSize(12.px)
                .fontWeight(FontWeight.Normal)
                .color(Theme.Black.rgb)
                .toAttrs()
        ) {
            Text(testimonials.review)
        }

    }
}