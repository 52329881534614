package au.com.aonea.sections

import androidx.compose.runtime.Composable
import au.com.aonea.models.Section
import au.com.aonea.models.Theme
import au.com.aonea.util.Constant
import com.varabyte.kobweb.compose.css.FontStyle
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun introLoader(breakpoint: Breakpoint) {
    Box(modifier = Modifier
        .fillMaxWidth()
        .fillMaxHeight(),
    contentAlignment = Alignment.Center) {
        if (breakpoint < Breakpoint.SM) smlScreenIntro() else introPara(breakpoint = breakpoint)

    }
}

@Composable
fun smlScreenIntro() {
    Column (
        modifier = Modifier
            .fillMaxWidth(90.percent)
            .margin(
                topBottom = 55.px
            )
    ,
        verticalArrangement = Arrangement.SpaceBetween,
        horizontalAlignment = Alignment.CenterHorizontally
            ){

        Row (
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        ){

            P(
                attrs = Modifier
                    .id(Section.INTRODUCTION_PARA.id)
                    .fontSize(16.px)
                    .fontStyle(FontStyle.Normal)
                    .fontFamily()
                    .fontWeight(FontWeight.Normal)
                    .toAttrs()
            ) {
                Text(Constant.INTRO_GAIN)
            }

        }


        P(
            attrs = Modifier
                .id(Section.INTRODUCTION_PARA.id)
                .fontSize(16.px)
                .fontStyle(FontStyle.Normal)
                .fontFamily(Constant.FONT_FAMILY)
                .fontWeight(FontWeight.ExtraBlack)
                .toAttrs()
        ) {
            Text(Constant.INTRO_ENGAGE_SML_1)
        }
        P(
            attrs = Modifier
                .id(Section.INTRODUCTION_PARA.id)
                .fontSize(16.px)
                .fontStyle(FontStyle.Normal)
                .fontFamily(Constant.FONT_FAMILY)
                .fontWeight(FontWeight.ExtraBlack)
                .toAttrs()
        ) {
            Text(Constant.INTRO_ENGAGE_SML_2)
        }


        Link(
            path = Section.CONTACT_FORM.path,
            openInternalLinksStrategy = OpenLinkStrategy.IN_PLACE

        ){
            P(
                attrs = Modifier
                    .id(Section.INTRODUCTION_PARA.id)
                    .fontSize(14.px)
                    .fontStyle(FontStyle.Normal)
                    .fontWeight(FontWeight.SemiBold)
                    .color(Theme.AONEABlue.rgb)
                    .toAttrs()
            ) {
                Text(Constant.INTRO_CONTACT)
            }

        }

    }
}
@Composable
fun introPara(breakpoint: Breakpoint) {
    Column (
        modifier = Modifier
            .fillMaxWidth(85.percent)
            .margin(
                leftRight = 0.px,
                topBottom = 55.px
            )
        ,
        verticalArrangement = Arrangement.SpaceBetween,
        horizontalAlignment = Alignment.CenterHorizontally
            ){

        Row (
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
                ){

            P(
                attrs = Modifier
                    .id(Section.INTRODUCTION_PARA.id)
                    .fontSize(if (breakpoint < Breakpoint.MD) 16.px else if (breakpoint >= Breakpoint.LG) 25.px else 20.px)
                    .fontStyle(FontStyle.Normal)
                    .fontFamily()
                    .fontWeight(FontWeight.Normal)
                    .toAttrs()
            ) {
                Text(Constant.INTRO_GAIN)
            }

        }


        P(
            attrs = Modifier
                .id(Section.INTRODUCTION_PARA.id)
                .fontSize(if (breakpoint < Breakpoint.MD) 16.px else if (breakpoint >= Breakpoint.LG) 25.px else 20.px)
                .fontStyle(FontStyle.Normal)
                .fontFamily(Constant.FONT_FAMILY)
                .fontWeight(FontWeight.ExtraBlack)
                .toAttrs()
        ) {
            Text(Constant.INTRO_ENGAGE)
        }
        Link(
            path = Section.CONTACT_FORM.path,
            openInternalLinksStrategy = OpenLinkStrategy.IN_PLACE

        ){
            P(
                attrs = Modifier
                    .id(Section.INTRODUCTION_PARA.id)
                    .fontSize(if (breakpoint < Breakpoint.MD) 14.px else if (breakpoint >= Breakpoint.LG) 23.px else 18.px)
                    .fontStyle(FontStyle.Normal)
                    .fontWeight(FontWeight.SemiBold)
                    .color(Theme.AONEABlue.rgb)
                    .toAttrs()
            ) {
                Text(Constant.INTRO_CONTACT)
            }

        }

    }
}