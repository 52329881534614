package au.com.aonea.sections

import androidx.compose.runtime.Composable
import au.com.aonea.components.sectionTitle
import au.com.aonea.components.testimonialCard
import au.com.aonea.models.Section
import au.com.aonea.models.Testimonials
import au.com.aonea.models.Theme
import au.com.aonea.util.Constant
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun testimonialsSection(breakpoint: Breakpoint) {
    Box(
        modifier = Modifier
            .id(Section.TESTIMONIALS.id)
            .maxWidth(500.px)
            .padding(topBottom = Constant.SECTION_PADDING.px),
        contentAlignment = Alignment.Center
    ) {
        testimonialsContent(breakpoint = breakpoint)
    }
}

@Composable
fun testimonialsContent(
    breakpoint: Breakpoint
) {
    Column(
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint >= Breakpoint.MD) 100.percent
                else 90.percent
            ),
        horizontalAlignment = Alignment.CenterHorizontally
    )
    {
        sectionTitle(
            modifier = Modifier
                .fillMaxWidth()
                .margin(bottom = 25.px),
            alignment = Alignment.CenterHorizontally,
            title = Section.TESTIMONIALS.title
        )
        Box (
            contentAlignment = Alignment.Center
                ) {
            testimonialCards(breakpoint = breakpoint)

//            P(
//                attrs = Modifier
//                    .fillMaxWidth()
//                    .margin(
//                        left = 0.px,
//                        top = 0.px,
//                        bottom = 0.px
//                    )
//                    .fontSize(16.px)
//                    .fontWeight(FontWeight.ExtraLight)
//                    .color(Theme.FirstContrast.rgb)
//                    .toAttrs()
//            ) {
//                Text(Constant.UNDER_CONSTRUCTION)
//            }

        }
    }
}

@Composable
fun testimonialCards(
    breakpoint: Breakpoint
) {
    SimpleGrid(
        modifier = Modifier,
        numColumns = numColumns(base = 1, sm = 2, md = 2)
    )
    {
            Testimonials.values().forEach { testimonial ->
                testimonialCard(
                    modifier = Modifier,
                    testimonials = testimonial,
                    breakpoint = breakpoint
                )
        }
    }
}





















