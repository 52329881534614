package au.com.aonea.sections

import androidx.compose.runtime.Composable
import au.com.aonea.models.Section
import au.com.aonea.models.Theme
import au.com.aonea.styles.tpbLogoStyle
import au.com.aonea.util.Constant
import au.com.aonea.util.Res
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun titleLoader(breakpoint: Breakpoint) {
    Box(
        modifier = Modifier
            .fillMaxHeight()
            .fillMaxWidth()
//            .backgroundColor(Theme.FirstContrast.rgb)
        ,
        contentAlignment = Alignment.Center
    ) {
        if (breakpoint < Breakpoint.SM) smlScreenTitle() else title(breakpoint = breakpoint)

    }
}

@Composable
fun smlScreenTitle(
    modifier: Modifier = Modifier
) {
    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.SpaceEvenly
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.End
        ) {
            Link(
                path = Constant.TPB_LINK,
                openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
            ) {

                Image(
                    modifier = tpbLogoStyle.toModifier()
                        .margin(right = 25.px, top = 25.px)
                        .size(130.px),
                    src = Res.Images.tbp_logo,
                    desc = ""
                )
            }
        }
        Image(
            modifier = Modifier
                .maxHeight(150.px)
                .margin(bottom = 50.px),
            src = Res.Images.aonea_logo,
            desc = "Advantage One Accounting",

            )
    }

}

@Composable
fun title(
    breakpoint: Breakpoint
) {
    Row(
        modifier = Modifier
            .id(Section.HEADER.id)
            .height(210.px)
            .fillMaxWidth(if (breakpoint >= Breakpoint.MD) 85.percent else 95.percent)
            .backgroundColor(Theme.White.rgb),
        horizontalArrangement = Arrangement.SpaceAround,
        verticalAlignment = Alignment.CenterVertically

    ) {


        Image(
            modifier = Modifier
                .maxHeight(if (breakpoint >= Breakpoint.MD) 230.px else 165.px),
            src = Res.Images.aonea_logo,
            desc = "",

            )
        Link(
            path = Constant.TPB_LINK,
            openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
        ) {

            Image(
                modifier = tpbLogoStyle.toModifier()
                    .size(if (breakpoint >= Breakpoint.MD) 180.px else 140.px),
                src = Res.Images.tbp_logo,
                desc = ""
            )
        }
    }

}


@Composable
fun xeroLogo() {
    Box(
        modifier = Modifier
            .minWidth(100.px)
            .fillMaxHeight()
            .backgroundColor(Theme.AONEABlue.rgb)
            .borderRadius(topLeft = 12.px),
        contentAlignment = Alignment.BottomEnd
    ) {


    }
}
