package au.com.aonea.components

import androidx.compose.runtime.Composable
import au.com.aonea.models.Services
import au.com.aonea.models.Theme
import au.com.aonea.styles.ConfirmTickStyle
import au.com.aonea.util.Res
import com.varabyte.kobweb.compose.css.FontStyle
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P

@Composable
fun serviceCard(
    skill: Services,
    breakpoint: Breakpoint
) {
    Column(
        modifier = Modifier
            .margin(all = 20.px)
//            .maxWidth(Constant.SECTION_WIDTH.px)
            .padding(all = 20.px),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {


        Row (
            modifier = Modifier.margin(bottom = 12.px),
            verticalAlignment = Alignment.CenterVertically
                ){


            P(
                attrs = Modifier
                    .fillMaxWidth()
//                    .margin(top = 20.px)
                    .fontSize(if (breakpoint <= Breakpoint.SM) 14.px else if (breakpoint >= Breakpoint.LG) 25.px else 18.px)
                    .padding(all = 4.px)
                    .outlineColor(Theme.AONEABlue.rgb)
                    .backgroundColor(Theme.MintCream.rgb)
                    .outlineWidth(2.px)
                    .outlineStyle(LineStyle.Solid)
                    .fontWeight(FontWeight.Normal)
                    .fontStyle(FontStyle.Italic)
                    .toAttrs()
            ) {
                SpanText(skill.title)
            }
            Image(
                modifier = ConfirmTickStyle.toModifier()
                    .height(if (breakpoint <= Breakpoint.SM) 38.px else if (breakpoint >= Breakpoint.LG) 55.px else 48.px)
                    .margin(left = 16.px)
                ,
                src = if (skill.offered) Res.Icons.tick else Res.Icons.cross

            )

        }

        Image(
            modifier = Modifier
                .opacity(if (skill.offered) 77.percent else 30.percent)
                .width(if (breakpoint <= Breakpoint.SM) 175.px else if (breakpoint >= Breakpoint.LG) 250.px else 200.px),
            src = skill.img,
            desc = ""
        )


    }
}