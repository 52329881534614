package au.com.aonea.styles

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.TransitionProperty
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent

val ConfirmTickStyle by ComponentStyle {
    base {
        Modifier
            .opacity(65.percent)
            .transition(CSSTransition(property = TransitionProperty.All, duration = 200.ms))
    }

    hover {
        Modifier
            .opacity(100.percent)
    }
}